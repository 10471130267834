import React from 'react';
import './style.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export class TopicButton extends React.Component {
	@observable style = {
		color: this.props.record.colorText,
		background: this.props.record.colorBackground,
		borderColor: this.props.record.colorBackground,
	};

	onMouseDown = (e) => {
		this.style = {
			color: this.props.record.colorBackground,
			background: this.props.record.colorText,
			borderColor: this.props.record.colorBackground,
		};
	};

	resetStyle = (e) => {
		this.style = {
			color: this.props.record.colorText,
			background: this.props.record.colorBackground,
			borderColor: this.props.record.colorBackground,
		};
	};

	render() {
		const { record } = this.props;
		return (
			<a
				href={record.link}
				className='topic-button'
				style={this.style}
				target='_blank'
				onMouseDown={this.onMouseDown}
				onMouseUp={this.resetStyle}
				onDragEnd={this.resetStyle}
			>
				{record.label}
			</a>
		);
	}
}
