import React, { Component } from 'react';
import './banner.css';
import GosuslugiSVG from './pos-icons/gosuslugi.svg';
import BusinessSVG from './pos-icons/business.svg?url';

export default class PosBanner extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <div class="banner-pb_wrapper">
		        <div class="banner-pb_container">
		            <div class="banner-pb_image"></div>
		            <div class="banner-pb_content">
		                <div class="banner-pb_content__info">
		                    <p class="banner-pb_content__title">
		                        Возникли трудности при ведении бизнеса в регионе?
		                    </p>
		                    <p class="banner-pb_content__subtitle">
		                        Напишите, чтобы быстро получить ответ
		                    </p>
		                </div>
		                <div class="banner-pb_content__link">
		                    <a rel="noopener" href="https://www.gosuslugi.ru/help/obratitsya_business" target="_blank">
		                        <div class="banner-pb_button">
		                            Написать
		                        </div>
		                    </a>
		                    <GosuslugiSVG className="banner-pb_icon" />
		                </div>
		            </div>
		        </div>
		    </div>
		);
	}
}