import React, { Component } from 'react';
import t from 'i18n';
import { Button } from 'components';
import IconMinus from 'img/icon-minus.svg';
import { Field, MaskedInput, Row } from '@smartplatform/ui';

class LegalEntityItems extends Component {
	render() {
		const { coAuthorDelete, coAuthorWithoutMiddleName } = this.props;
		return (
			<>
				<div className='legal-entity-items'>
					<div className='p2'>{t('citizensAppeals.coAuthorAppeal')}</div>
					<Button color='transparent-underline' onClick={() => coAuthorDelete()} className='legal-entity-button' prependIcon={<IconMinus />}>
						{t('citizensAppeals.removeCoAuthor')}
					</Button>
				</div>
				<Row>
					<Field property='coAuthorFirstName' className='field-label' label={t('citizensAppeals.firstName')} isRequired />
					<Field property='coAuthorName' className='field-label' label={t('citizensAppeals.name')} isRequired />
				</Row>
				<Row>
					<Field property='coAuthorMiddleName' className='field-label' label={t('citizensAppeals.middleName')} disabled={coAuthorWithoutMiddleName} />
					<Field property='coAuthorWithoutMiddleName' label={t('citizensAppeals.withoutMiddleName')} className='field-label without-middle-name' />
				</Row>
				<Row>
					<Field property='coAuthorPhone' className='field-label' label={t('citizensAppeals.phone')} isRequired>
						<MaskedInput class="ui-input text-input" mask='+7 (999) 999-99-99' />
					</Field>
					<Field property='coAuthorEmail' className='field-label' label={t('citizensAppeals.email')} isRequired />
				</Row>
			</>
		);
	}
}

export default LegalEntityItems;
