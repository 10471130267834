import React, { Component } from 'react';
import t from 'i18n';
import { Field, MaskedInput, RelationSelect, Row } from '@smartplatform/ui';

class AuthorAppeal extends Component {
	render() {
		const { withoutMiddleName } = this.props;
		return (
			<>
				<div className='p2'>{t('citizensAppeals.authorAppeal')}</div>
				<Row>
					<Field property='firstName' className='field-label input-appeals' label={t('citizensAppeals.firstName')} isRequired />
					<Field property='name' className='field-label input-appeals' label={t('citizensAppeals.name')} isRequired />
				</Row>
				<Row>
					<Field property='middleName' className='field-label input-appeals' label={t('citizensAppeals.middleName')} disabled={withoutMiddleName} />
					<Field property='withoutMiddleName' label={t('citizensAppeals.withoutMiddleName')} className='field-label input-appeals without-middle-name' />
				</Row>
				<Row>
					<Field property='phone' className='field-label input-appeals' label={t('citizensAppeals.phone')} >
						<MaskedInput class="ui-input text-input" mask='+7 (999) 999-99-99' />
					</Field>
					<Field property='email' className='field-label input-appeals' label={t('citizensAppeals.email')} isRequired />
				</Row>
				<Field relation='socialStatus' property='name' className='field-label input-appeals' label={t('citizensAppeals.socialStatus')}>
					<RelationSelect searchFields={['name']} />
				</Field>
			</>
		);
	}
}

export default AuthorAppeal;
